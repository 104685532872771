// Initial State
const initialState = {
    siteEditAction: null,
    pageEditAction: null,
    siteListDeleteItem: null,
    siteCountryListDeleteItem: null,
    siteCountryLanguageDeleteItem: null,
    siteCountryLanguagePageDeleteItem: null
}

const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SITE_EDIT_ACTION":
            return { ...state, siteEditAction: action.data }
        case "PAGE_EDIT_ACTION":
            return { ...state, pageEditAction: action.data }
        case "SITE_LIST_DELETE_ITEM":
            return { ...state, siteListDeleteItem: action.data }
        case "SITE_COUNTRY_LIST_DELETE_ITEM":
            return { ...state, siteCountryListDeleteItem: action.data }
        case "SITE_COUNTRY_LANGUAGE_DELETE_ITEM":
            return { ...state, siteCountryLanguageDeleteItem: action.data }
        case "SITE_COUNTRY_LANGUAGE_PAGE_DELETE_ITEM":
            return { ...state, siteCountryLanguagePageDeleteItem: action.data }
        default:
            return { ...state }
    }
}

export default siteReducer
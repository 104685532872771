const initialState = {
  HomeData: {
    success:"",
    statusCode:"",
    message:"",
    data: [],
    params: {}
  },
  ShowData: {
    data: []
    }
}
  
  const homeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_HOMEDATA':
        return {
            ...state,
            HomeData:{
              success:action.success,
              statusCode:action.statusCode,
              message:action.message,
              data: action.data,
              params: action.params
            },
            ShowData:{ data: action.data }
          }
        case 'UPDATE_HOMEDATA':
          return {
            ...state,
            ShowData:{ data: action.data }
          }
      default:
        return { ...state }
    }
  }
  export default homeReducer
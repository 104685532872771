// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import siteList from "./site"
// ** CMS
import contentManagement from '@src/views/cms/content-management/store/reducer'
import reviewer from '@src/views/cms/reviewer/store/reducer'
import rolesList from '@src/views/cms/roles/store/reducer'
import financeSettingList from '@src/views/cms/financeSetting/store/reducer'
import tpmsdata from '@src/views/cms/tpms/store/reducer'
import customerdata from '@src/views/cms/customer/store/reducer'
import checkAvailability from '@src/views/cms/customer/CheckAvailability/store/reducer'
import questionlist from '@src/views/cms/customer/QuestionList/store/reducer'
import sharelist from '@src/views/cms/customer/sharelist/store/reducer'
import home from '@src/views/cms/home/store/reducer'
import logindata from '@src/views/cms/SSOLogin/store/reducer'
import email from '@src/views/cms/email/store/reducer'
import dashboard from '@src/views/cms/dashboard/store/reducer'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  siteList,
  contentManagement,
  reviewer,
  rolesList,
  financeSettingList,
  //#region ** TPMS(Car、Images、360)
  tpmsdata,
  //#endregion
  //#region ** Customer(List,Details)
  customerdata,
  //#endregion
  checkAvailability,
  questionlist,
  sharelist,
  home,
  logindata,
  email,
  dashboard
})

export default rootReducer

const initialState = {
  questionlistData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  questiondetailData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table: []
    },
    params: {}
  }
}

const questionlist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QuesitonDATA':
      return {
        questionlistData: {
          ...state,
          success: action.questionlistData.success,
          statusCode: action.questionlistData.statusCode,
          message: action.questionlistData.message,
          data: action.questionlistData.data,
          params: action.questionlistData.params
        }
      }
    case 'GET_QUESTIONDATA':
      return {
        ...state,
        questiondetailData: {
          success: action.questiondetailData.success,
          statusCode: action.questiondetailData.statusCode,
          message: action.questiondetailData.message,
          data: action.questiondetailData.data,
          params: action.questiondetailData.params
        }
      }
    default:
      return { ...state }
  }
}
export default questionlist

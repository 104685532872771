const initialState = {
  ListData: {
    success:false,
    statusCode:0,
    message:null,
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  DetailData: {
    success:false,
    statusCode:0,
    message:null,
    data: [],
    params: {}
  }
}
  
  const reviewer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_LISTDATA':
        return {
          ...state,
          ListData: {
            success: action.success,
            statusCode: action.statusCode,
            message: action.message,
            data:action.data,
            params: action.params
          }
        }
      case 'GET_DETAILDATA':
        return {
          ...state,
          DetailData: {
            success: action.success,
            statusCode: action.statusCode,
            message: action.message,
            data:action.data,
            params: action.params
          }
        }
      case 'UPDATE_DETAILDATA':
        return {
          ...state,
          DetailData:{...state.DetailData, data: action.data }
        }
      default:
        return { ...state }
    }
  }
  export default reviewer
  
// ** Initial State
const initialState = {
    TpmsUploadData: {
        success: "",
        statusCode: "",
        message: "",
        data: {
            total_count: 1,
            page_count: 1,
            table: []
        },
        params: {}
    },
    CarListData: {
        success: "",
        statusCode: "",
        message: "",
        data: {
            total_count: 1,
            page_count: 1,
            table: []
        },
        params: {}
    },
    CarViewData: {
        success: "",
        statusCode: "",
        message: "",
        data: {
            table: []
        },
        params: {}
    },
    CarImagesData: {
        success: "",
        statusCode: "",
        message: "",
        data: {
            table1: [],
            table2: []
        },
        params: {}
    },
    Car360Data: {
        success: "",
        statusCode: "",
        message: "",
        data: {
            table1: [],
            table2: []
        },
        params: {}
    }
}

const TpmsTablesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TPMSUPLOADDATA':
            return {
                ...state,
                TpmsUploadData: {
                    success: action.TpmsUploadData.success,
                    statusCode: action.TpmsUploadData.statusCode,
                    message: action.TpmsUploadData.message,
                    data: action.TpmsUploadData.data,
                    params: action.TpmsUploadData.params
                }
            }
        case 'GET_CARLISTDATA':
            return {
                ...state,
                CarListData: {
                    success: action.CarListData.success,
                    statusCode: action.CarListData.statusCode,
                    message: action.CarListData.message,
                    data: action.CarListData.data,
                    params: action.CarListData.params
                }
            }
        case 'GET_CARVIEWDATA':
            return {
                ...state,
                CarViewData: {
                    success: action.CarViewData.success,
                    statusCode: action.CarViewData.statusCode,
                    message: action.CarViewData.message,
                    data: action.CarViewData.data,
                    params: action.CarViewData.params
                }
            }
        case 'GET_CARIMAGESDATA':
            return {
                ...state,
                CarImagesData: {
                    success: action.CarImagesData.success,
                    statusCode: action.CarImagesData.statusCode,
                    message: action.CarImagesData.message,
                    data: action.CarImagesData.data,
                    params: action.CarImagesData.params
                }
            }
        case 'GET_CAR360DATA':
            return {
                ...state,
                Car360Data: {
                    success: action.Car360Data.success,
                    statusCode: action.Car360Data.statusCode,
                    message: action.Car360Data.message,
                    data: action.Car360Data.data,
                    params: action.Car360Data.params
                }
            }
        default:
            return state
    }
}

export default TpmsTablesReducer

const initialState = {
  FinacesettingData: {
    success: false,
    statusCode: "",
    message: "",
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  FinacesettingparentData: {
    success: false,
    statusCode: "",
    message: "",
    data: { table: [] },
    params: {}
  }
}


const financeSetting = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_FinanceDATA':
      return {
        ...state,
        FinacesettingData: {
          success: action.FinacesettingData.success,
          statusCode: action.FinacesettingData.statusCode,
          message: action.FinacesettingData.message,
          data: action.FinacesettingData.data,
          params: action.FinacesettingData.params
        }
      }
    case 'GET_FinanceparmentDATA':
      return {
        ...state,
        FinacesettingparentData: {
          success: action.FinacesettingparentData.success,
          statusCode: action.FinacesettingparentData.statusCode,
          message: action.FinacesettingparentData.message,
          data: action.FinacesettingparentData.data,
          params: action.FinacesettingparentData.params
        }
      }
    default:
      return { ...state }
  }
}
export default financeSetting

import React, { useState, useEffect } from 'react'
import { getCompanyConfig } from "@utils"
import logo from '@src/assets/images/logo/logo.png'
import themeConfig from '@configs/themeConfig'

const SpinnerComponent = () => {
  const [companyLogo, setCompanyLogo] = useState(themeConfig.app.appsilkReloLogo)

  useEffect(() => {
    if (getCompanyConfig()?.company_Logo) {
      setCompanyLogo(getCompanyConfig()?.company_Logo)
    }
  }, [])
  return (
    <div className='fallback-spinner vh-100 d-flex justify-content-center align-items-center'>
      <img className='' src={companyLogo || logo} alt='logo' style={{ maxHeight: "100px" }} />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent

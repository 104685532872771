const initialState = {
  LogionData: {
    success: "",
    statusCode: "",
    message: "",
    login_name:"",
    login_time:null,
    last_update_time:null
  }
}

const financeSetting = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LoginDATA':
      return {
        LogionData:{
          ...state,
          success: action.LogionData.success,
          statusCode: action.LogionData.statusCode,
          message: action.LogionData.message,
          login_name: action.LogionData.login_name,
          login_time: action.LogionData.login_time,
          last_update_time: action.LogionData.last_update_time
        }
      }
    default:
      return { ...state }
  }
}
export default financeSetting

// ** Initial State
const initialState = {
  CustomerList: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 0,
      page_count: 0,
      table: []
    },
    params: {}
  },
  CustomerHeader: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table1: [],
      table2: []
    },
    params: {}
  },
  CustomerOrderList: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 0,
      page_count: 0,
      table: []
    },
    params: {}
  },
  CustomerActivityList: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 0,
      page_count: 0,
      table: []
    },
    params: {}
  },
  CustomerBrowsingList: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 0,
      page_count: 0,
      table: []
    },
    params: {}
  },
  CustomerOrderChat: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table: []
    },
    params: {}
  },
  CustomerOrderDetail: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table: []
    },
    params: {}
  }
}
const CustomerViewDataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CUSTOMERLIST':
      return {
        ...state,
        CustomerList: {
          success: action.CustomerList.success,
          statusCode: action.CustomerList.statusCode,
          message: action.CustomerList.message,
          data: action.CustomerList.data,
          params: action.CustomerList.params
        }
      }
    case 'GET_CUSTOMERHEADER':
      return {
        ...state,
        CustomerHeader: {
          success: action.CustomerHeader.success,
          statusCode: action.CustomerHeader.statusCode,
          message: action.CustomerHeader.message,
          data: action.CustomerHeader.data,
          params: action.CustomerHeader.params
        }
      }
    case 'GET_CUSTOMERORDERLIST':
      return {
        ...state,
        CustomerOrderList: {
          success: action.CustomerOrderList.success,
          statusCode: action.CustomerOrderList.statusCode,
          message: action.CustomerOrderList.message,
          data: action.CustomerOrderList.data,
          params: action.CustomerOrderList.params
        }
      }
    case 'GET_CUSTOMERACTIVITYLIST':
      return {
        ...state,
        CustomerActivityList: {
          success: action.CustomerActivityList.success,
          statusCode: action.CustomerActivityList.statusCode,
          message: action.CustomerActivityList.message,
          data: action.CustomerActivityList.data,
          params: action.CustomerActivityList.params
        }
      }
    case 'GET_CUSTOMERBROWSINGLIST':
      return {
        ...state,
        CustomerBrowsingList: {
          success: action.CustomerBrowsingList.success,
          statusCode: action.CustomerBrowsingList.statusCode,
          message: action.CustomerBrowsingList.message,
          data: action.CustomerBrowsingList.data,
          params: action.CustomerBrowsingList.params
        }
      }
      case 'GET_CUSTOMERORDERCHAT':
      return {
        ...state,
        CustomerOrderChat: {
          success: action.CustomerOrderChat.success,
          statusCode: action.CustomerOrderChat.statusCode,
          message: action.CustomerOrderChat.message,
          data: action.CustomerOrderChat.data,
          params: action.CustomerOrderChat.params
        }
      }
      case 'GET_CUSTOMERORDERDETAIL':
        return {
          ...state,
          CustomerOrderDetail: {
            success: action.CustomerOrderDetail.success,
            statusCode: action.CustomerOrderDetail.statusCode,
            message: action.CustomerOrderDetail.message,
            data: action.CustomerOrderDetail.data,
            params: action.CustomerOrderDetail.params
          }
        }
    default:
      return state
  }
}

export default CustomerViewDataTablesReducer
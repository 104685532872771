import { TrendingUp, User, Box, DollarSign } from 'react-feather'
const initialState = {
    OrdersStatus: {
        success: "false",
        statusCode: "",
        message: "",
        data: []
    }
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DETAILDATA':
            const OrdersData = []
            if (action.data !== null && action.data.length === 1) {
                const objs = action.data[0]
                for (const key in objs) {
                    const data = { title: objs[key] }
                    if (key === 'AllOrders') {
                        const iconobj = <TrendingUp size={24} />
                        Object.assign(data, { icon: iconobj, color: 'light-primary', subtitle: 'All Orders' })
                        OrdersData.push(data)
                    } else if (key === 'OpenOrders') {
                        const iconobj = <User size={24} />
                        Object.assign(data, { icon: iconobj, color: 'light-info', subtitle: 'Open Orders' })
                        OrdersData.push(data)
                    } else if (key === 'Completed') {
                        const iconobj = <Box size={24} />
                        Object.assign(data, { icon: iconobj, color: 'light-danger', subtitle: 'Completed' })
                        OrdersData.push(data)
                    }
                }
            }
            return {
                ...state,
                OrdersStatus: {
                    success: action.success,
                    statusCode: action.statusCode,
                    message: action.message,
                    data: OrdersData
                }
            }
        default:
            return { ...state }
    }
}
export default dashboardReducer